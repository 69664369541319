.hero {
  --vertical-padding: 163px;
  --gap: 32px;

  position: relative;

  padding-top: calc(var(--vertical-padding));
  padding-bottom: var(--vertical-padding);
}

@media (--tablet) {
  .hero {
    --vertical-padding: 60px;
  }
}
@media (--mobile) {
  .hero {
    --vertical-padding: 40px;
    --gap: 24px;
  }
}

.hero--size-small {
  --vertical-padding: 48px;
}

.hero__background--left {
  position: absolute;

  top: 0;
  bottom: 0;
  left: 0;

  display: block;
  height: 100%;

  pointer-events: none;
  user-select: none;
}

.hero__background--left img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hero__background--right {
  position: absolute;

  top: 0;
  right: 0;
  bottom: 0;

  display: block;
  height: 100%;

  pointer-events: none;
  user-select: none;
}

.hero__background--right img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (max-width: 1500px) {
  .hero__background--left {
    transform: translate(-35%, 0);
  }
  .hero__background--right {
    transform: translate(45%, 0);
  }
}

@media (max-width: 850px) {
  .hero__background--left {
    transform: translate(-55%, 0);
  }
  .hero__background--right {
    transform: translate(60%, 0);
  }
}

.hero--gradient-bottom-radial .hero__gradient {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: radial-gradient(
    50% 85% at 50% 100%,
    rgba(227, 104, 100, 0.3) 0%,
    rgba(227, 104, 100, 0) 100%
  );
}

.hero__content {
  position: relative;

  text-align: center;
}

.hero__title {
  font-size: 56px;
  font-weight: 700;
  line-height: 1.2;
  color: var(--white);
}
.hero__title strong {
  color: var(--primary-500);
}
@media (--tablet) {
  .hero__title {
    font-size: 40px;
  }
}
@media (--mobile) {
  .hero__title {
    font-size: 32px;
  }
  .hero__title br {
    display: none;
  }
}

.hero__description {
  max-width: 44ch;
  margin-right: auto;
  margin-left: auto;

  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: rgba(var(--white-rgb), 0.8);
}
* + .hero__description {
  margin-top: var(--gap);
}
@media (--mobile) {
  .hero__description {
    font-size: 17px;
  }
}

* + .hero__cta {
  margin-top: var(--gap);
}
